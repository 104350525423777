import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getDictionary, getField } from "../../utils/translation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./SignInUpPhoneInput.css";
// import {
//   parsePhoneNumber,
//   validatePhoneNumberLength,
//   isSupportedCountry,
//   isValidPhoneNumber,
// } from "libphonenumber-js/mobile";
import { validatePhone, handleOnKeyPress } from "../../utils/validation";

const SignInUpPhoneInput = ({
  supportedLanguages,
  translation,
  language,
  phone,
  setPhone,
  placeholder,
  isSubmitting,
  phoneError,
  setPhoneError,
  availableRestaurants,
  selectedRestaurantData,
  keepInitialPhoneValue,
}) => {
  const dictionary = getDictionary(translation, "login-page");

  const languageMapping = {
    english: "en",
    arabic: "ar",
    french: "fr",
    spanish: "es",
    german: "de"
  };

  const [languageFiles, setLanguageFiles] = useState({});
  const [languageFilesLoaded, setLanguageFilesLoaded] = useState(false);

  // const validatePhone = () => {
  //   if (!phone?.phone) {
  //     setPhoneError(getField(dictionary, "required", language));
  //     return;
  //   }
  //   if (phone?.country && phone?.phone === phone?.dialCode) {
  //     setPhoneError(getField(dictionary, "add-phone-number", language));
  //     return;
  //   }
  //   if (phone?.phone && !phone?.country) {
  //     setPhoneError(getField(dictionary, "invalid-dial-code", language));
  //     return;
  //   }
  //   if (
  //     phone?.phone &&
  //     phone?.phone !== "" &&
  //     phone?.country &&
  //     phone?.country !== "" &&
  //     isSupportedCountry(phone?.country?.toUpperCase())
  //   ) {
  //     const dialCode = phone?.dialCode;
  //     const phoneValue = phone?.phone?.trim();
  //     const countryValue = phone?.country?.toUpperCase();
  //     // Check if the input value starts with the dial code and is not equal to the dial code
  //     let formattedPhoneValue = "";
  //     if (phoneValue.startsWith(dialCode) && phoneValue !== dialCode) {
  //       // Remove the dial code from the input value
  //       formattedPhoneValue = phoneValue.slice(dialCode.length).trim();
  //     } else {
  //       formattedPhoneValue = phoneValue?.trim();
  //     }
  //     const phoneNumberLength = validatePhoneNumberLength(
  //       formattedPhoneValue,
  //       countryValue
  //     );

  //     if (phoneNumberLength === "TOO_SHORT") {
  //       setPhoneError(getField(dictionary, "too-short-phone-number", language));
  //       return;
  //     }
  //     try {
  //       const parsedNumber = parsePhoneNumber(
  //         formattedPhoneValue,
  //         countryValue
  //       );

  //       if (!parsedNumber.isValid()) {
  //         setPhoneError(getField(dictionary, "invalid-phone-number", language));
  //         return;
  //       }
  //     } catch (error) {
  //       // Handle parsing error
  //       setPhoneError(
  //         getField(dictionary, "phone-number-parsing-error", language)
  //       );
  //       return;
  //     }
  //   }
  //   //else
  //   setPhoneError("");
  // };

  //   const handleOnChange = (value, country, e) => {
  //   e.preventDefault();

  //   const dialCode = country?.dialCode;
  //   const phoneValue = value?.trim();

  //   if (phoneValue) {
  //     try {
  //       let formattedPhoneValue = "";
  //       if (phoneValue.startsWith(dialCode) && phoneValue !== dialCode) {
  //         // Remove the dial code from the input value
  //         formattedPhoneValue = phoneValue.slice(dialCode.length).trim();
  //       } else {
  //         formattedPhoneValue = phoneValue?.trim();
  //       }

  //       const phoneLengthValidation = validatePhoneNumberLength(formattedPhoneValue, country?.countryCode?.toUpperCase());

  //       if (phoneLengthValidation === "TOO_LONG") {
  //         // Set phoneValue to the current phone value to prevent updating
  //         setPhone((prevPhone) => ({
  //                   ...prevPhone,
  //                   phone: prevPhone.phone,
  //                   country: country?.countryCode,
  //                   dialCode: dialCode,
  //                 }));
  //                 return;
  //               }
  //     } catch (error) {
  //       setPhoneError(getField(dictionary, "phone-number-parsing-error", language));
  //       return;
  //     }
  //   }
  //   setPhone({
  //     phone: phoneValue,
  //     country: country?.countryCode,
  //     dialCode: dialCode,
  //   });
  // };

  // const handleOnKeyPress = (e) => {
  //   const { keyCode, code } = e;
  //   const dialCode = phone?.dialCode;
  //   const country = phone?.country?.toUpperCase();

  //   // Exclude backspace and delete keys
  //   if (
  //     keyCode === 8 ||
  //     code === "Backspace" ||
  //     keyCode === 46 ||
  //     code === "Delete"
  //   ) {
  //     return;
  //   }

  //   const value = phone?.phone?.trim();

  //   try {
  //     let formattedPhoneValue = "";
  //     if (value.startsWith(dialCode) && value !== dialCode) {
  //       // Remove the dial code from the input value
  //       formattedPhoneValue = value.slice(dialCode.length).trim() + "1";
  //     } else {
  //       formattedPhoneValue = value.trim();
  //     }

  //     const phoneLengthValidation = validatePhoneNumberLength(
  //       formattedPhoneValue,
  //       country
  //     );

  //     if (phoneLengthValidation === "TOO_LONG") {
  //       // Prevent updating the phone state if the length is too long
  //       e.preventDefault();
  //       return;
  //     }
  //   } catch (error) {
  //     setPhoneError(
  //       getField(dictionary, "phone-number-parsing-error", language)
  //     );
  //     return;
  //   }
  // };

  // const handleOnChange = (value, country) => {
  //   const dialCode = country?.dialCode;
  //   const phoneValue = value?.trim();

  //   setPhone({
  //     phone: phoneValue,
  //     country: country?.countryCode,
  //     dialCode: dialCode,
  //   });
  // };

  const handleOnChange = (value, country) => {
    const dialCode = country?.dialCode;
    const phoneValue = value?.trim();

    setPhone({
      phone: phoneValue,
      country: country?.countryCode,
      dialCode: dialCode,
    });

    // Reset phone.phone to null when a new country is selected
    if (phone?.country !== country?.countryCode) {
      setPhone((prevPhone) => ({
        ...prevPhone,
        phone: null,
      }));
    }
  };

  const handleOnInput = (e) => {
    if (!e.target) {
      return;
    }

    const input = e.target;
    const { value } = input;
    const { dialCode } = phone;

    // If the user tries to delete the dial code, add it back
    if (!value.startsWith(dialCode)) {
      input.value = dialCode + value.substring(dialCode.length);
      setPhone({
        ...phone,
        phone: dialCode + value.substring(dialCode.length),
      });
    }
  };

  const handleOnPaste = (event) => {
    const { value } = event.target;
    const { dialCode } = phone;

    // If the user pastes a value without the dial code, add the dial code to it
    if (!value.startsWith(`+${dialCode}`)) {
      event.preventDefault();
      event.stopPropagation();

      const formattedPhone = `+${dialCode}${value}`;
      setPhone({
        phone: formattedPhone,
        country: phone.country,
        dialCode: dialCode,
      });
    }
  };


  useEffect(() => {
    const importLanguageFiles = async () => {
      const loadedLanguageFiles = {};

      if (supportedLanguages?.length > 0) {
        try {
          const importPromises = supportedLanguages
            .filter((lang) => lang?.name !== "english")
            .map((lang) =>
              import(`react-phone-input-2/lang/${languageMapping[lang?.name]}.json`)
            );

          const resolvedImports = await Promise.all(importPromises);

          resolvedImports.forEach((importResult, index) => {
            const actualLanguage = supportedLanguages.filter(
              (lang) => lang?.name !== "english"
            )[index];
            loadedLanguageFiles[actualLanguage] = importResult.default;
          });

          setLanguageFiles(loadedLanguageFiles);
          setLanguageFilesLoaded(true);
        } catch (error) {
          console.error(error);
        }
      }
    };

    importLanguageFiles();
  }, [supportedLanguages])


  useEffect(() => {
    if (!keepInitialPhoneValue) {
      if (selectedRestaurantData) {
        setPhone({
          phone: "",
          country: selectedRestaurantData?.country?.code?.toLowerCase(),
          dialCode: selectedRestaurantData?.country?.phone,
        });
        return;
      }
      if (availableRestaurants?.length > 0) {
        setPhone({
          phone: "",
          country: availableRestaurants[0]?.country?.code?.toLowerCase(),
          dialCode: availableRestaurants[0]?.country?.phone,
        });
        return;
      }
    }
  }, [selectedRestaurantData, availableRestaurants]);

  useEffect(() => {
    const validatePhoneFunction = () => {
      const result = validatePhone(phone);
      if (result?.valid) {
        setPhoneError("");
        return;
      }
      let errorText = "";
      if (result?.error === "required") {
        errorText = getField(dictionary, "required", language);
      }
      if (result?.error === "missing-phone-number") {
        errorText = getField(dictionary, "add-phone-number", language);
      }
      if (result?.error === "too-short") {
        errorText = getField(dictionary, "too-short-phone-number");
      }
      if (result?.error === "invalid-phone-number") {
        errorText = getField(dictionary, "invalid-phone-number", language);
      }
      if (result?.error === "parsing-error") {
        errorText = getField(dictionary, "phone-number-parsing-error");
      }
      setPhoneError(errorText);
    };
    validatePhoneFunction();
  }, [phone?.phone, phone?.dialCode, phone?.country]);

  return (
    <>
      {languageFilesLoaded && (
        <div
          className={`signin-up-phone-input-wrapper ${
            language === "arabic" ? "rtl" : ""
          }`}
        >
          <PhoneInput
            enableTerritories={true}
            disableCountryGuess={true}
            countryCodeEditable={false}
            localization={
              language !== "english" &&
              Object.keys(languageFiles).length > 0 &&
              languageFiles[language]
                ? languageFiles[language]
                : undefined
            }
            country={phone?.country}
            enableSearch
            searchPlaceholder={getField(
              dictionary,
              "search-placeholder",
              language
            )}
            containerClass={`signin-up-phone-input-container ${
              isSubmitting && phoneError ? "error" : ""
            }`}
            value={phone?.phone || ""}
            onChange={handleOnChange}
            onKeyDown={(e) => {
              handleOnKeyPress(e, phone);
            }}
            placeholder={placeholder}
          />
          <p className="signin-up-phone-input-error">
            {isSubmitting && phoneError ? phoneError : ""}
          </p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  supportedLanguages: state.translation.supportedLanguages,
  translation: state.translation.translation,
  language: state.language.language,
  availableRestaurants: state.restaurants.availableRestaurants,
  selectedRestaurantData: state.restaurants.selectedRestaurantData,
});

export default connect(mapStateToProps, null)(SignInUpPhoneInput);

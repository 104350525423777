import {
  parsePhoneNumber,
  validatePhoneNumberLength,
  isSupportedCountry,
} from "libphonenumber-js/max";

export const getValidEmailExtensions = async () => {
  try {
    const response = await fetch(
      "https://publicsuffix.org/list/public_suffix_list.dat"
    );
    const text = await response.text();
    const validExtensions = text
      .split("\n")
      .filter((line) => !line.startsWith("//") && !line.startsWith("!"))
      .map((line) => line.trim())
      .filter((line) => line !== "");
    return validExtensions;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const validateEmail = (
  email,
  validEmailExtensions,
  isRequired = false
) => {
  if (email === "" || !email) {
    if (isRequired) {
      return {
        valid: false,
        error: "required",
      };
    }
    //else
    return {
      valid: true,
    };
  }

  const emailRegex = /^\S+@\S+\.\S+$/;
  if (!emailRegex.test(email)) {
    return {
      valid: false,
      error: "invalid-email",
    };
  }
  //if valid email extensions is not empty, validate extension
  if (validEmailExtensions) {
    const domain = email.split(".").pop();
    if (!validEmailExtensions.includes(domain)) {
      return {
        valid: false,
        error: "invalid-email-extension",
      };
    }
    return {
      valid: true,
    };
  }
};

export const validatePhone = (phoneObject) => {
  const phoneValue = phoneObject?.phone?.trim();
  const countryValue = phoneObject?.country;
  const dialCode = phoneObject?.dialCode;

  let error = "";

  if (!phoneValue) {
    error = "required";
  } else if (phoneValue === dialCode) {
    error = "missing-phone-number";
  } else if (
    phoneValue &&
    phoneValue !== "" &&
    countryValue &&
    countryValue !== "" &&
    isSupportedCountry(countryValue?.toUpperCase())
  ) {
    // Check if the input value starts with the dial code and is not equal to the dial code
    let formattedPhoneValue = "";

    if (phoneValue.startsWith(dialCode) && phoneValue !== dialCode) {
      // Remove the dial code from the input value
      formattedPhoneValue = phoneValue.slice(dialCode.length).trim();
    } else {
      formattedPhoneValue = phoneValue?.trim();
    }

    const phoneNumberLength = validatePhoneNumberLength(
      formattedPhoneValue,
      countryValue?.toUpperCase()
    );

    if (phoneNumberLength === "TOO_SHORT") {
      error = "too-short";
    } else {
      try {
        const parsedNumber = parsePhoneNumber(
          formattedPhoneValue,
          countryValue?.toUpperCase()
        );

        if (!parsedNumber.isValid()) {
          error = "invalid-phone-number";
        } else {
          error = "";
        }
      } catch (error) {
        // Handle parsing error
        error = "parsing-error";
      }
    }
  }
  if (error) {
    return {
      valid: false,
      error: error,
    };
  } else {
    return {
      valid: true,
    };
  }
};

export const handleOnKeyPress = (e, phoneObject) => {
  const { keyCode, code } = e;
  const dialCode = phoneObject?.dialCode;
  const country = phoneObject?.country?.toUpperCase();

  // Exclude backspace and delete keys
  if (
    keyCode === 8 ||
    code === "Backspace" ||
    keyCode === 46 ||
    code === "Delete"
  ) {
    return;
  }

  const value = phoneObject?.phone?.trim();

  try {
    let formattedPhoneValue = "";
    if (value.startsWith(dialCode) && value !== dialCode) {
      // Remove the dial code from the input value
      formattedPhoneValue = value.slice(dialCode.length).trim() + "1";
    } else {
      formattedPhoneValue = value.trim();
    }

    const phoneLengthValidation = validatePhoneNumberLength(
      formattedPhoneValue,
      country
    );

    if (phoneLengthValidation === "TOO_LONG") {
      // Prevent updating the phone state if the length is too long
      e.preventDefault();
      return;
    }
  } catch (error) {
    return "parsing-error";
  }
};
